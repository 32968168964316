import { useState, useEffect } from "react"
import _ from "lodash"
import validateForm from "./formValidator"

const initializeFormState = fields =>
  _.reduce(
    fields,
    (initialState, field) => ({
      ...initialState,
      [field.name]: field.defaultValue,
    }),
    {}
  )

/**
 * React hook to manage state of dynamic forms
 * @param {function} onSubmit
 * @param {Array} fields field definitions for dynamic form
 * @param {boolean} validateOnChange validate on every input change. TODO if extending : make default false -> validate on submit.
 */
const useForm = (onSubmit, fields, validateOnChange = true) => {
  const [values, setValues] = useState(initializeFormState(fields))
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (validateOnChange) {
      setErrors(validateForm(fields, values))
    }
  }, [values])

  const handleSubmit = event => {
    if (!_.isEmpty(errors)) {
      return event.preventDefault()
    }
    return onSubmit()
  }

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
}

export default useForm
