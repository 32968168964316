import { INLINES } from "@contentful/rich-text-types"
import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>
          {children}
        </a>
      )
    },
  },
}

export default ({ document }) => documentToReactComponents(document, OPTIONS)
