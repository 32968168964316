import _ from "lodash"

const VALIDATORS = {
  required: value => !value && `is required`,
}

/**
 * @param {Object} field defines validators to execute on validators property
 * @param {Primitive} value current value of field
 * @returns {Array} containing the validation messages of each validator
 */
const validateField = (field, value) =>
  _.reduce(
    field.validators,
    (errors, validator) => {
      const errorMessage = VALIDATORS[validator](value, field.label)
      if (errorMessage) {
        return [...errors, errorMessage]
      }
      return errors
    },
    []
  )

/**
 * @param {Object} fields object defining dynamic form
 * @param {Object} values current form state
 * @returns {Object} has key and array of error messages for each invalid field
 */
const validateForm = (fields, values) =>
  _.reduce(
    fields,
    (formErrors, field) => {
      const fieldErrors = validateField(field, values[field.name])
      if (fieldErrors.length) {
        return { ...formErrors, [field.name]: fieldErrors }
      }
      return formErrors
    },
    {}
  )

export default validateForm
