import React from "react"
import PropTypes from "prop-types"
import ContentfulRichText from "components/ContentfulRichText"
import styles from "./FormFieldCheckbox.module.scss"

const FormFieldCheckbox = ({ name, type, label, value, onChange, text }) => (
  <label
    onClick={() => onChange(name, !value)}
    className={styles.formFieldCheckbox}
    htmlFor={name}
  >
    <input
      checked={value}
      name={name}
      type={type}
      onChange={() => onChange(name, !value)}
    />
    <span className={styles.label}>
      {text ? <ContentfulRichText document={text.json} /> : label}
    </span>
  </label>
)

export const checkboxPropTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["checkbox"]).isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.shape(),
}

FormFieldCheckbox.propTypes = checkboxPropTypes

FormFieldCheckbox.defaultProps = {
  value: false,
  text: null,
}

export default FormFieldCheckbox
