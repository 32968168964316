/**
 * This is component is used to add hidden fields from zoho embed form to contact form
 * TODO: use rest api istead of mocking embed form to send data to Zoho (will need to set up api proxy for authentication)
 * This component should be deleted when TODO complete
 * Form: Zoho whitepaper landing page form
 */
import React from "react"
import { Location } from "@reach/router"

const ZohoHiddenFields = () => (
  <Location>
    {({ location }) => (
      <div style={{ display: "none" }}>
        <input
          type="text"
          name="xnQsjsdp"
          value="ab7db38d5efbf0b257123858c9b960c1f10d4e54011dc2ab6ecb48142704f733"
        />
        <input type="hidden" name="zc_gad" id="zc_gad" value="" />
        <input
          type="text"
          name="xmIwtLD"
          value="fb1651d61ff3456d40f93e8a907dc796ac9929a5c966fb8ba1d67456a579c49c"
        />
        <input type="text" name="actionType" value="TGVhZHM=" />
        <input
          type="text"
          name="returnURL"
          value={`${location.origin}${location.pathname}`}
        />
      </div>
    )}
  </Location>
)

export default ZohoHiddenFields
