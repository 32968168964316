import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import * as Button from "components/Button"
import FormFieldInput from "./FormFieldInput"
import FormFieldCheckBox from "./FormFieldCheckbox"
import ZohoHiddenFields from "./ZohoHiddenFields"
import useForm from "./useForm"

const FORM_FIELD_COMPONENTS = {
  text: FormFieldInput,
  email: FormFieldInput,
  checkbox: FormFieldCheckBox,
}

const mapFieldsToComponent = (fields, handleChange, values) =>
  fields.map(field => {
    const FieldComponent = FORM_FIELD_COMPONENTS[field.type]
    return FieldComponent ? (
      <FieldComponent
        {...field}
        key={field.name}
        onChange={handleChange}
        value={values[field.name]}
      />
    ) : null
  })

const Form = ({ submitLabel, action, name, fields, onSubmit }) => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    onSubmit,
    fields,
    true
  )

  const submitDisabled = _.isNull(errors) || !_.isEmpty(errors)

  return (
    <form action={action} method="POST" onSubmit={handleSubmit} name={name}>
      {action.includes("zoho") && (
        /** TODO: send data via rest api, delete this component  */
        <ZohoHiddenFields />
      )}
      {mapFieldsToComponent(fields, handleChange, values)}
      <Button.Submit disabled={submitDisabled}>{submitLabel}</Button.Submit>
    </form>
  )
}

const FormFieldPropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(_.keys(FORM_FIELD_COMPONENTS)).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  validators: PropTypes.arrayOf(PropTypes.string),
}

Form.propTypes = {
  action: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(FormFieldPropTypes)).isRequired,
  submitLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
}

Form.defaultProps = {
  onSubmit: _.noop(),
}

export default Form
