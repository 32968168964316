import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import styles from "./FormFieldInput.module.scss"

const isRequired = validators => _.indexOf(validators, "required") > -1

const FormFieldInput = ({ name, label, type, validators, onChange, value }) => (
  <label className={styles.FormFieldInput} htmlFor={name}>
    <span className={styles.label}>{label}</span>
    <input
      value={value}
      name={name}
      type={type}
      onChange={event => onChange(name, event.target.value)}
      placeholder={`${label}${isRequired(validators) ? "*" : ""}`}
    />
  </label>
)

export const inputPropTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email"]).isRequired,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
}

FormFieldInput.propTypes = inputPropTypes

FormFieldInput.defaultProps = {
  value: "",
  validators: null,
}

export default FormFieldInput
