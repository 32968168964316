import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "components/CookieConsent"

const Layout = ({ children }) => {
  return (
    <div className="app-container">
      {children}
      <CookieConsent />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
