import React, { useContext } from "react"
import { AnalyticsContext } from "components/analyticsModule/AnalyticsContext"
import { useStaticQuery, graphql } from "gatsby"
import { CSSTransition } from "react-transition-group"
import classnames from "classnames"
import ContentfulRichText from "components/ContentfulRichText"

import styles from "./CookieConsent.module.scss"

const cookieQuery = graphql`
  query CookieQuery {
    allContentfulCookieConsent {
      edges {
        node {
          node_locale
          body {
            json
          }
          acceptButton
        }
      }
    }
  }
`

const CookieConsent = () => {
  const { hasCookieConsent, setCookieConsent } = useContext(AnalyticsContext)

  const { allContentfulCookieConsent } = useStaticQuery(cookieQuery)

  const { node: cookieData } = allContentfulCookieConsent.edges.find(
    edge => edge.node.node_locale === "en-US"
  )

  return (
    <CSSTransition
      in={hasCookieConsent === null}
      timeout={2000}
      appear
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <div className={classnames(styles.cookieConsent, styles[state])}>
          <div className={styles.info}>
            <ContentfulRichText document={cookieData.body.json} />
          </div>
          <button
            type="button"
            className={styles.button}
            onClick={() => setCookieConsent(true)}
          >
            {cookieData.acceptButton}
          </button>
        </div>
      )}
    </CSSTransition>
  )
}

export default CookieConsent
