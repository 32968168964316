import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import useLocalStorage from "hooks/useLocalStorage"
import Form from "components/formsModule"
import BackgroundImage from "components/BackgroundImage"
import Layout from "components/Layout"
import SEO from "components/Seo"
import * as Button from "components/Button"
import siteLogo from "assets/images/svg/site-logo.svg"
import { AnalyticsContext } from "components/analyticsModule/AnalyticsContext"
import ContentfulRichText from "components/ContentfulRichText"
import styles from "./GatedDownloadPage.module.scss"

const GatedDownloadPage = ({ data }) => {
  const { copyright } = data.site.siteMetadata
  const gatedDownload = data.allContentfulGatedDownload.gatedDownloads[0].node

  const [hasDownloadAccess, setDownloadAccess] = useLocalStorage(
    gatedDownload.slug
  )

  const [showForm, setShowForm] = useState(true)

  const { trackGatedDownload } = useContext(AnalyticsContext)

  const startDownload = () => window.open(gatedDownload.download.file.url)

  const handleSubmit = () => {
    trackGatedDownload()
    startDownload()
    setDownloadAccess(true)
  }

  useEffect(() => {
    if (hasDownloadAccess) {
      setShowForm(false)
    }
  }, [])

  return (
    <Layout header={false} footer={false}>
      <SEO {...gatedDownload.seo} />
      <div className={styles.template}>
        <BackgroundImage image={gatedDownload.backgroundImage} />
        <div className={styles.copySection}>
          <img className={styles.logo} src={siteLogo} alt="dcmn logo" />
          <ContentfulRichText document={gatedDownload.copy.json} />
          <i className={`${gatedDownload.icon}-white`} />
          <p className={styles.siteCopyright}>
            {copyright}
            {" - "}
            <a href="https://www.dcmn.com/legal">Imprint</a>
          </p>
        </div>

        <div className={styles.form}>
          <i className={`${gatedDownload.icon}`} />
          {!showForm ? (
            <>
              <Img
                className={styles.coverImage}
                fadeIn={false}
                fluid={gatedDownload.downloadImage.fluid}
              />
              <Button.Primary onClick={startDownload}>
                {gatedDownload.form.submitLabel}
              </Button.Primary>
            </>
          ) : (
            <>
              <h2>{gatedDownload.formHeading.formHeading}</h2>
              <Form {...gatedDownload.form} onSubmit={handleSubmit} />
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const gatedDownloadFragment = graphql`
  fragment GatedDownload on ContentfulGatedDownload {
    slug
    copy {
      json
    }
    icon
    formHeading {
      formHeading
    }
    backgroundImage {
      fluid(quality: 70, maxWidth: 1440) {
        ...GatsbyContentfulFluid
      }
    }
    download {
      id
      file {
        url
        fileName
      }
    }
    downloadImage {
      fluid(quality: 50, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    form {
      ...Form
    }
    seo {
      ...SEO
    }
  }
`

export const formFragment = graphql`
  fragment Form on ContentfulForm {
    action
    name
    submitLabel
    fields: formFields {
      ... on Node {
        ... on ContentfulFormFieldCheckbox {
          name
          label
          type
          validators
          text {
            json
          }
        }
        ... on ContentfulFormFieldTextInput {
          name
          label
          type
          validators
        }
      }
    }
  }
`

export const seoFragment = graphql`
  fragment SEO on ContentfulPageSeo {
    title
    description
    keywords
    previewImage {
      file {
        url
      }
    }
  }
`

export const query = graphql`
  query GatedDownloadQuery($slug: String, $locale: String) {
    allContentfulGatedDownload(
      filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      gatedDownloads: edges {
        node {
          ...GatedDownload
        }
      }
    }
    site {
      siteMetadata {
        copyright
      }
    }
  }
`

GatedDownloadPage.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default GatedDownloadPage
