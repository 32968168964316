import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styles from "./Button.module.scss"

const BUTTON_STYLES = {
  PRIMARY: "primary",
}

const Button = ({ children, onClick, type, label, disabled, buttonStyle }) => (
  // eslint-disable-next-line react/button-has-type
  <button
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={styles[buttonStyle]}
  >
    {children || label}
  </button>
)

export const Submit = props => <Button {...props} type="submit" />

export const Primary = props => <Button {...props} />

Button.propTypes = {
  buttonStyle: PropTypes.oneOf(_.values(BUTTON_STYLES)),
  type: PropTypes.oneOf(["button", "submit"]),
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

Button.defaultProps = {
  buttonStyle: BUTTON_STYLES.PRIMARY,
  type: "button",
  children: null,
  disabled: false,
  onClick: _.noop(),
  label: "",
}
