import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

/**
 * Component to set gatsby-image optimized background image
 */
const BackgroundImage = ({ image }) => (
  <Img
    fluid={image.fluid}
    style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: "-1",
    }} // keep styles inline to override gatsby-image wrapper styles
  />
)

BackgroundImage.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.shape.isRequired,
  }).isRequired,
}

export default BackgroundImage
